import React, {useState} from "react"
import {graphql} from "gatsby"

import Animation from "../../components/animation"

import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"
import HeaderSection from "../../components/header"
import {MailButtonLink} from "../../components/button/link"
import Image from "../../components/image"
import MediaQuery from "react-responsive/src/Component"
import Carousel from "../../components/carousel"
import Contact from "../../components/contact"
import {ImageListCarousel} from "../../components/image/list"
import Balloon from "../../components/balloon";

import Modal from "../../components/modal";
import Popup from "../../components/popup";

import Money from "../../../public/assets/icons/money.svg";
import Vacation from "../../../public/assets/icons/vacation.svg";
import Development from "../../../public/assets/icons/development.svg";
import Puzzle from "../../../static/assets/icons/puzzle.svg";
import Clock from "../../../static/assets/icons/clock.svg";
import Dialog from "../../../static/assets/icons/dialog.svg";
import People from "../../../static/assets/icons/people.svg";
import Prototyping from "../../../public/assets/icons/prototyping.svg";
import DoubleArrowRight from "../../../static/assets/icons/double-arrow-right.svg"

import Vivien from "../../../public/assets/avatars/people/vivien-schindler.svg";
import TeamPic from "../../../src/images/career/gallery/TeamCareer.png";
import ComeTogetherPic from "../../../src/images/career/gallery/come-together.jpg";
import {useMediaQuery} from "react-responsive/src";
import Ref from "react-pdf/dist/umd/Ref";

const VacanciesPage = ({_path, data}) => {

    return (
    <Layout path={"/karriere"}>
      <SEO title="Karriere"/>
      <HeaderSection
        type={"image-fullscreen"}
        title={"Karriere"}
        image={data.headerImage.childImageSharp}
      />
      <DescriptionSection/>
      <VacanciesSection people={[data.modalBalloonPerson1, data.modalBalloonPerson2, data.modalBalloonPerson3, data.modalBalloonPerson4, data.modalBalloonPerson5]}/>
      <IntroMachineSection/>
      <MachineSection/>
      <BalloonSection person={data.balloonPerson}/>
      <ImagesSection images={data.imageList.edges}/>
      <CoffeeQuestionSection/>
      <ContactSection person={data.contactPerson}/>
    </Layout>
  )
}

const DescriptionSection = () => (
  <Section id={"DescriptionSection"}>
    <Container appear={"fromLeft"}>
      <Box
        padding={"horizontal"}
        border={"left"}
        borderColor={"red"}
      >
        <h2>Was dich erwartet</h2>
        <p>
          Wir sind ein kleines Unternehmen mit familiärer Arbeitskultur, in dem sich jeder aktiv einbringen kann.
          Du gestaltest deine Arbeit und die Umgebung mit und kannst deine Ideen jederzeit mit deinem Team teilen.
        </p>
        <p>
          Wir legen hohe Maßstäbe an uns und streben nach den bestmöglichen Ergebnissen. Dabei arbeiten wir flexibel
          und bestehen nicht auf eine 40h-Woche oder einen festen Arbeitsplatz. Ob bei uns im Büro, bei dir zuhause
          oder wo du sonst noch ins Internet kommst – Hauptsache, du fühlst dich wohl beim Arbeiten und deiner
          Produktivität steht nichts im Wege.
        </p>
      </Box>
    </Container>
  </Section>
)


const VacanciesSection = (props) => {
    const[modal, setModal] = useState(false);
    const[modal2, setModal2] = useState(false);

    const isMobile = useMediaQuery(
        { maxDeviceWidth: 450 },
        // { deviceWidth: 1600 } // `device` prop
    )

    return(
        <Section appear={"fromRight"} style={{marginBottom: "7.5em"}}>
            <Container appear={"fromRight"}>
                <Box padding={"horizontal"}>
                    <h2>Wir suchen</h2>
                </Box>
            </Container>
            <Container
                padding={"horizontal"}
                justifyContent={"left"}
                sizeItems={"xs1-sm2-md3"}
                spaceItems={"between-1"}
                appear={"instantFromLeft"}
            >
                <Box
                    padding={"full"}
                    boxShadow={"bottom-right"}
                    size={"fill"}
                    onClick={()=>setModal(true)}
                    style={{cursor:"pointer"}}
                >
                    <h4 style={{ height: "2.5em" }}>Senior Softwareentwickler (m/w/d)
                    </h4>
                    <p>in Braunschweig und Oldenburg</p>
                    <MailButtonLink style={{ marginTop: "2em" }} mailto={"bewerbung@red-oak-consulting.com"}>
                        <span> jetzt bewerben </span>
                    </MailButtonLink>
                </Box>

                <Modal
                    className={"people-modal"}
                    background={"darken"}
                    open={modal}
                    onClick={()=>({})}
                >
                    <Popup open={true} clickOnClose={()=>setModal(false)}>
                        <MediaQuery maxWidth="450px">
                            {(matches) =>
                                matches
                                    ? <img src={TeamPic} width={"100%"} alt={"team"} style={{marginTop:"30px"}}/>

                                    : <img src={TeamPic} width={"100%"} alt={"team"}/>
                            }
                        </MediaQuery>

                        <div style={{width:"100%", backgroundColor:"white"}}>

                            <h4 style={{color:"black", marginLeft:"50px", marginRight:"50px"}}>Bist auch du ein Oakie?</h4>
                            <p style={{fontWeight:"500", marginLeft:"50px", marginRight:"50px"}}>
                                Du suchst ehrliche Softwareentwicklung und ein starkes Team? Dabei jagst du nicht nur den
                                neuesten Trends der Softwareentwicklung hinterher, sondern suchst individuell passende
                                Lösungen für das eigentliche Problem des Kunden? Dann bist du vielleicht auch ein Oakie!
                            </p>
                            <p style={{fontWeight:"bolder", marginLeft:"50px", marginRight:"50px", color:"black"}}>
                                Wir suchen Verstärkung für unsere Teams in Braunschweig und Oldenburg als
                                <span style={{color:"red", fontWeight:"700", display:"block", marginTop:"10px"}}>
                                    Senior Softwareentwickler (m/w/d)
                                </span>
                            </p>

                            <div style={{padding:"50px", margin:"50px", marginBottom:"25px", border:"3px solid #D3D3D3"}}>
                                <h2 style={{fontWeight:"bolder", color:"black"}}>Was erwartet dich als Senior Softwareentwickler?</h2>
                                <img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span> Gemeinsam mit deinem Team entwickelst du maßgeschneiderte Softwarelösungen für unsere
                                        Kunden und nutzt dabei moderne Technologien, Frameworks und Tools.</span>
                                <br/><img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span> Du beteiligst dich aktiv an der Konzeption, Entwicklung und Pflege cloudbasierter Systeme und
teilst dein Wissen durch Code-Reviews und Know-how-Transfer innerhalb deines Teams.</span>
                                <br/><img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span> Im engen Austausch mit unseren Kunden arbeitest du an der Lösung fachlicher Herausforderungen
und stellst sicher, dass ihre Anforderungen optimal umgesetzt werden.</span>


                                <h2 style={{marginTop:"50px", fontWeight:"bolder", color:"black"}}>Was erwarten wir von dir als Senior Softwareentwickler?</h2>
                                <img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span> Du bereicherst unser Team mit einem hohen Qualitätsbewusstsein und der Bereitschaft,
eigenverantwortlich zu arbeiten.
</span>
                                <br/>
                                <img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span> Du verfügst über umfangreiche Erfahrung in der Implementierung moderner Webservices
sowie ein fundiertes Verständnis von Webentwicklungsprinzipien und Best Practices (React,
Node und TypeScript).</span>

                                <br/>
                                <img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span>Du besitzt umfassendes Know-how in Cloud-Technologien wie AWS, Google Cloud oder Azure
und setzt dieses Wissen ein, um robuste und skalierbare Lösungen zu entwickeln.
</span>
                                <br/><img src={DoubleArrowRight} alt="DoubleArrowRight" style={{width:"10px", marginTop:"25px"}}/>
                                <span>Mit einer ausgeprägten „You build it, you run it“-Mentalität übernimmst du Verantwortung
für deine Arbeit und sicherst so die Qualität und Stabilität unserer Systeme.
</span>


                            </div>
                            <p style={{marginLeft:"50px", marginRight:"50px", marginBottom:"20px"}} className={"text-color-red text-weight-bold"}>
                                Wer wir sind und was uns in unserer Arbeit antreibt, das können dir wohl am besten unsere
                                Entwickler:innen selbst beantworten:
                            </p>
                            <Balloon.Connector justify={"left"}>
                                <Balloon justify={"left"} author={props.people[1]} >
                                    <h2 className={"title"}>Was ist dir an der Softwareentwicklung besonders wichtig?</h2>
                                    <p>
                                        Die Software, die ich entwickelt habe, soll einen <span style={{fontWeight:"bold"}}>Mehrwert</span>  haben und tatsächlich
                                        verwendet werden. Ich mag es, auch die Chance zu haben, mich allein in ein Problem
                                        zu vertiefen, es anzugehen und mich dann <span style={{fontWeight:"bold"}}>mit meinem Team darüber auszutauschen.</span>
                                    </p>
                                </Balloon>
                                <Balloon justify={"left"} author={props.people[0]} >
                                    <p>Für mich braucht es eine Kombi aus abstraktem Vorstellungsvermögen und einem <span style={{fontWeight:"bold"}}>konkreten Problemlöseverständnis. </span>
                                    </p>
                                </Balloon>
                            </Balloon.Connector>

                            <Balloon.Connector justify={"right"}>
                                <Balloon justify={"left"} author={props.people[2]} >
                                    <h2 className={"title"}>Was braucht ein guter Softwareentwickler?</h2>
                                    <p>Ein guter Softwareentwickler sollte die <span style={{fontWeight:"bold"}}>Standards der Softwareentwicklung</span> beherrschen
                                        in einer Qualität, in der man nicht nur mit Stackoverflow programmiert. Ein Interesse an
                                        aktuellen Themen in der IT-Branche sollte selbstverständlich sein.
                                    </p>
                                </Balloon>
                                <Balloon justify={"left"} author={props.people[1]} >
                                    <p>
                                        Viele Softwareentwickler versteifen sich zu sehr auf bestimmte Tools und Methoden,
                                        anstatt erstmal zu versuchen, das <span style={{fontWeight:"bold"}}>eigentliche Problem </span>und <span style={{fontWeight:"bold"}}>die Fachlichkeit</span> zu verstehen,
                                        um im Team gemeinsam gute Lösungen zu finden.
                                    </p>
                                </Balloon>
                            </Balloon.Connector>

                            <Balloon.Connector justify={"left"}>
                                <Balloon justify={"left"} author={props.people[2]} >
                                    <h2 className={"title"}>Was macht Red Oak anders als andere Unternehmen?</h2>
                                    <p>Wir stellen gute Arbeit in den Vordergrund und haben weniger unnötige organisatorische
                                        Aspekte. Und wir führen <span style={{fontWeight:"bold"}}>direkte, offene und ehrliche Kommunikation</span> mit allen Beteiligten
                                        auf Augenhöhe.
                                    </p>
                                </Balloon>
                                <Balloon justify={"left"} author={props.people[0]} >
                                    <p>Mir ist es wichtig, <span style={{fontWeight:"bold"}}>verantwortungsbewusst</span>zu arbeiten und stetig die Konsequenzen
                                        vom eigenen Handeln zu hinterfragen. Bei Red Oak wissen wir, was wir mit unserer Arbeit
                                        erreichen können und arbeiten die Aufgaben nicht einfach stumpf ab.</p>
                                </Balloon>

                                <Balloon justify={"left"} author={props.people[4]} >
                                    <p>Bei Red Oak habe ich alle <span style={{fontWeight:"bold"}}>Freiheiten</span>, die ich brauche.</p>
                                </Balloon>
                            </Balloon.Connector>

                            <h4 style={{color:"black", marginBottom:"30px", marginLeft:"50px", marginRight:"50px", marginTop:"40px"}}>Und jetzt noch kurz etwas zum Job

                            </h4>
                            <div className={"icons"} style={{display:"flex", justifyContent:"space-evenly", alignItems:"center", flexWrap:"wrap"}}>
                                <MediaQuery maxWidth="450px">
                                    {(matches) =>
                                        matches
                                            ? <div style={{width:"100%", height:"180px", marginBottom:"50px", textAlign:"center"}}>
                                                <img src={People} alt="vacation" style={{width:"70px"}}/>
                                                <span style={{display:"block"}}>Projektarbeit mit</span>
                                                <span style={{display:"block"}}>dem Kunden</span>
                                            </div>
                                            : <div style={{width:"25%", height:"180px", marginBottom:"50px", textAlign:"center"}}>
                                                <img src={People} alt="vacation" style={{width:"70px"}}/>
                                                <span style={{display:"block", marginTop:"20px"}}>Projektarbeit mit</span>
                                                <span style={{display:"block"}}>dem Kunden</span>
                                            </div>
                                    }
                                </MediaQuery>

                                <MediaQuery maxWidth="450px">
                                    {(matches) =>
                                        matches
                                            ? <div style={{width:"100%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Clock} alt="clock" style={{width:"55px", marginBottom:"7px"}}/>
                                                <span style={{display:"block"}}>Flexible Arbeitsumgebung</span>
                                                <span style={{display:"block", fontSize:"smaller"}}>(Zeit, Ort und was auch immer du</span>
                                                <span style={{display:"block", fontSize:"smaller"}}>flexibel gestalten möchtest)</span>
                                            </div>
                                            : <div style={{width:"25%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Clock} alt="clock" style={{width:"55px", marginBottom:"7px"}}/>
                                                <span style={{display:"block"}}>Flexible Arbeitsumgebung</span>
                                                <span style={{display:"block", fontSize:"smaller"}}>(Zeit, Ort und was auch immer du</span>
                                                <span style={{display:"block", fontSize:"smaller"}}>flexibel gestalten möchtest)</span>
                                            </div>
                                    }
                                </MediaQuery>

                                <MediaQuery maxWidth="450px">
                                    {(matches) =>
                                        matches
                                            ? <div style={{width:"100%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Dialog} alt="dialog" style={{width:"70px", marginBottom:"7px"}}/>
                                                <span style={{display:"block"}}>direkte, offene und</span>
                                                <span style={{display:"block"}}>ehrliche Kommunikation</span>
                                            </div>
                                            : <div style={{width:"25%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Dialog} alt="dialog" style={{width:"70px", marginBottom:"7px"}}/>
                                                <span style={{display:"block"}}>direkte, offene und</span>
                                                <span style={{display:"block"}}>ehrliche Kommunikation</span>
                                            </div>
                                    }
                                </MediaQuery>


                                <MediaQuery maxWidth="450px">
                                    {(matches) =>
                                        matches
                                            ? <div style={{width:"100%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Puzzle} alt="puzzle" style={{width:"60px"}}/>
                                                <span style={{display:"block"}}>Ein kleines, motiviertes Team</span>
                                            </div>
                                            : <div style={{width:"25%", height:"180px", marginBottom:"20px", textAlign:"center"}}>
                                                <img src={Puzzle} alt="puzzle" style={{width:"60px"}}/>
                                                <span style={{display:"block", lineHeight:"2.8"}}>Ein kleines, motiviertes Team</span>
                                            </div>
                                    }
                                </MediaQuery>

                            </div>

                            <h4 style={{color:"black", marginTop:"30px", marginLeft:"50px", marginRight:"50px"}}>Dein Kontakt rund um Bewerbungen:
                            </h4>
                            <Box size={"2-to-3"} padding={"full"}>
                                <Contact
                                    imageSrc={Vivien}
                                    title={"Vivien" + " " + "Schindler"}
                                    subtitle={"HR Managerin"}
                                    street={"Westbahnhof 11"}
                                    city={"38118 Braunschweig"}
                                    phone={"+49 151 560 039 34"}
                                    mail={"bewerbung@red-oak-consulting.com"}
                                    buttonUrl={"bewerbung@red-oak-consulting.com"}
                                />
                            </Box>

                        </div>
                    </Popup>
                </Modal>
            </Container>
        </Section>
    )
}


const IntroMachineSection = () => (
  <Section id={"IntroMachineSection"}>
    <Container justify={"center"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"} >
        <p className={"text-size-bigger"}>
         <span>Wir quetschen uns in keine Rollen oder Schubladen,</span>
        </p>
          <span className={"text-size-bigger text-color-red text-weight-bold"}>
            sondern treten als Team an.
          </span>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;
        </p>
        <p className={"text-size-big"}>
        Wir arbeiten gemeinsam an Lösungen
        und tauschen uns häufig und direkt miteinander aus. 
        So ist jeder bei uns auf dem gleichen Stand.
        </p>
      </Box>
    </Container>
  </Section>
)

const MachineSection = () => {

  const [paused, setPaused] = React.useState(true)

  return (
    <Section>
      <Container
        appear={"fromBottom"}
        onAppear={() => setPaused(false)}
      >
        <Box>
          <MediaQuery minWidth={768}>
            <Animation
              src={"machine-nah.json"}
              paused={paused}
            />
          </MediaQuery>
          <MediaQuery maxWidth={767.999}>
            <Animation
              src={"machine-nah-mobile.json"}
              paused={paused}
            />
          </MediaQuery>
        </Box>
      </Container>
    </Section>
  )
}

const BalloonSection = ({person}) => (
  <Section id={"BalloonSection"}>
    <Container justify={"left"} appear={"fromRight"}>
      <Balloon justify={"left"} author={person}>
        <p>
          Die Art und Weise, wie sich Red Oak um meine individuelle Weiterbildung kümmert,
          habe ich so noch nie bei jemand anderen gesehen.
          Ich kann mir sämtliche Arbeitsmaterialien und meine Schulungen selbst aussuchen
          und nicht nur Zertifikate sammeln. Wenn ich mein Weiterbildungsbudget am Ende
          des Jahres nicht aufgebraucht habe, kann ich dieses an eine Institution meiner Wahl spenden.
        </p>
      
        <p>
          Das ist eine riesige Freiheit, die ich hier habe.
        </p> 
        
      </Balloon>
    </Container>
  </Section>
)

const ImagesSection = ({images}) => {

  const shuffledImages = images.sort(() => Math.random() - 0.5)
  const [autoSlide, setAutoSlide] = React.useState(false)

  return (
    <Section>
      <Container
        alignItems={"end"}
        appear={"fromRight"}
        onAppear={() => setAutoSlide(true)}
      >
        <MediaQuery minWidth={768}>
          <ImageListCarousel
            images={shuffledImages}
            altPrefix={"Red Oak Consulting"}
            background={"right"}
          />
        </MediaQuery>
        <MediaQuery maxWidth={767.999}>
          <Box size={"2-to-3"} padding={"horizontal"}>
            <Carousel autoplay={autoSlide} autoplaySpeed={4000} dots={false} dotsSlider={true}>
              {shuffledImages.map((edge, _index) => (
                <Box key={edge.node.id}>
                  <Image fluid={edge.node.childImageSharp.fluid}/>
                </Box>
              ))}
            </Carousel>
          </Box>
        </MediaQuery>
      </Container>
    </Section>
  )
}

const CoffeeQuestionSection = () => (
  <Section id={"CoffeeQuestionSection"}>
    <Container justify={"center"} direction={"column"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"}>
        <p className={"text-size-bigger"}>
          <span>Du merkst, <wbr /></span>
          <span className={"text-color-red text-weight-bold"}>
            du passt zu uns?
          </span>
        </p>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;<br/>
        </p>
        <p className={"text-size-big"}>
          Dann komm auf einen Kaffee in Braunschweig oder Oldenburg vorbei, um über Software
          und Technologien zu philosophieren. Auch wenn keine passende Stelle für dich dabei ist oder wir zurzeit keine
          ausgeschrieben haben, freuen wir uns auf dich. Dein Kontakt für alle Fragen rund ums Bewerben und freie
          Stellen:
        </p>
      </Box>
    </Container>
  </Section>
)

const ContactSection = ({person}) => (
  <Section id={"ContactSection"} appear={"fromRight"}>
    <Container>
      <Box size={"2-to-3"}>
        <Contact
          imageFluid={person.frontmatter.square.childImageSharp.fluid}
          title={person.frontmatter.firstName + " " + person.frontmatter.lastName}
          subtitle={person.frontmatter.jobTitle}
          street={"Westbahnhof 11"}
          city={"38118 Braunschweig"}
          phone={person.frontmatter.phone}
          mail={"bewerbung@red-oak-consulting.com"}
          buttonType={"mail"}
          buttonUrl={"bewerbung@red-oak-consulting.com"}
          buttonText={"Kontakt aufnehmen"}
        />
      </Box>
    </Container>
  </Section>
)

const ModalQuoteSection = (props) => (
    <Section id={"modalQuote"}>
        <Container appear={"fromRight"} justify={"left"}>
            <h2 className={"title"}>Was ist dir an der Softwareentwicklung besonders wichtig?</h2>
            <Balloon justify={"left"} author={props.people[0]}>
                <p>
                    Die Software, die ich entwickelt habe, soll einen <span style={{fontWeight:"bold"}}>Mehrwert</span> haben und tatsächlich
                    verwendet werden. Ich mag es, auch die Chance zu haben, mich allein in ein Problem
                    zu vertiefen, es anzugehen und mich dann <span style={{fontWeight:"bold"}}> mit meinem Team darüber auszutauschen.</span>
                </p>
            </Balloon>
        </Container>
        <Container appear={"fromLeft"} justify={"right"}>
            <Balloon justify={"right"} author={props.people[1]}>
                <p>
                    Wir stellen gute Arbeit in den Vordergrund und haben weniger unnötige organisatorische Aspekte. Was ist das
                    konkrete Problem, wo müssen wir ansetzen und wie gelangen wir mit welchen Stakeholdern zu einer Lösung.
                </p>
            </Balloon>
        </Container>
    </Section>
)

export default VacanciesPage

export const query = graphql`
  query VacanciesQuery {
    site {
      siteMetadata {
        title
      }
    }

    headerImage: file(relativePath: {eq: "career/header.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    
        peopleAllMdx: allMdx(
      filter: { fileAbsolutePath: { regex: "/people/" } }
      sort: { order: ASC, fields: [frontmatter___position] }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            firstName
            lastName
            jobTitle
            avatar
            animation
            portrait {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            landscape {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    
    balloonPerson: mdx(fileAbsolutePath: {regex: "/people/christian-thode/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    modalBalloonPerson1: mdx(fileAbsolutePath: {regex: "/people/dean-eckert/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    modalBalloonPerson2: mdx(fileAbsolutePath: {regex: "/people/felix-krueger/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    modalBalloonPerson3: mdx(fileAbsolutePath: {regex: "/people/stephan-krauss/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    modalBalloonPerson4: mdx(fileAbsolutePath: {regex: "/people/robin-wloka/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    modalBalloonPerson5: mdx(fileAbsolutePath: {regex: "/people/christian-eilts/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }

    imageList: allFile(
      filter: {relativePath: {regex: "/career/gallery/.*.jpg/"}},
      sort: {fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    
    contactPerson : mdx(fileAbsolutePath: {regex: "/people/vivien-schindler/"}) {
      frontmatter {
        firstName
        lastName
        jobTitle
        mail
        phone
        square {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }        
  }
`
